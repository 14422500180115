.slidePane{
    max-width: 680px !important;   
}
.edit{
    background-color: transparent !important;
    padding: 0 !important;
    position: relative !important;
    top: -2px !important;
    left: 0px !important;
}
.leftMinus10{
    left:-10px !important;
    background-color: transparent !important;
}