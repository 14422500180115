.container{
    background: #1A365D;
    color: #FFFFFF;
    width: 100%;
    margin: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: left;
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: left;
    text-align: left;
    border-radius:0;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    position: relative;
    margin-top: 10px !important;
}
.slideLogo{
    font-size: 42px;
    position: absolute;
    top: 20px;
    left: 20px;
    font-weight: normal;
    color: #ffffff;
}
.th{
    color: #ffffff !important;
    font-weight: normal !important;
    border-color: rgb(0 160 218) !important;
    background-color: transparent !important;
}
.td {
    border-color: rgb(0 160 218) !important;
}
.edit{
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 15px !important;
    height: 15px !important;
    position: relative !important;
    top: -1px !important;
}