.slidePane{
    max-width: 680px !important;   
}
.edit{
    background-color: transparent !important;
    padding: 0 !important;
    position: relative !important;
    top: -2px !important;
    left: 0px !important;
}

.bp-widget-widget {
    top: auto;
    left: auto;
    bottom: 24px;
    right: 36px;
    width: 52px !important;
    height: 52px !important;
}

@media screen and (max-width: 980px) {
    .bp-widget-widget {
        top: auto;
        left: auto;
        bottom: 24px;
        right: 0px;
        width: 30px !important;
        height: 30px !important;
    }
    .bpw-floating-button{
        width: 30px !important;
        height: 30px !important;
    } 
}
@media screen and (max-width: 980px) {
    .bpw-floating-button{
        width: 30px !important;
        height: 30px !important;
    } 
}