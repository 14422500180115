.customHeadingH3{
    color: #343a40 !important;
    margin-bottom: 0.75rem !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
    margin-top: 10px !important;
}
.clearfix{
    clear: both;
}
.teamLink{
    color: #0056b3 !important;
}
.designation{
    font-size: 12px;
}
.imgContainer{
    border-bottom-right-radius: 8px;
    overflow: hidden;
    border-top-right-radius: 8px;
    background-color: #e5dfdf;
}
.customCardPo{
    width: 100%;
    position: relative;
    top: -39px;
}
.deleteCont{
    position: relative;
    z-index: 99;
    float: right;
}
.deleteButton{
    background-color: transparent !important;
    padding: 0;
}